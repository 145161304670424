import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import { Helmet } from "react-helmet";
import ClientTestimonials from "../components/client-testimonials";
import CtaBanner from "../components/cta-banner";
import HeroBanner from "../components/hero";
import Layout from "../components/layout";
import TwoColumnImageContent from "../components/two-column-image-content";

const WhatWeDoPage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "what-we-do" }) {
				whatWeDo {
					whatWeDoBannerSection {
						bannerSectionHeading
						bannerSectionTagline
						bannerSectionContent
						bannerSectionCta1 {
							title
							target
							url
						}
						bannerSectionCta2 {
							target
							title
							url
						}
						bannerSectionImage {
							node {
								altText
								sourceUrl
							}
						}
					}
					whatWeDoTwoColumnProgramme {
						twoColumnTagline
						twoColumnHeading
						twoColumnContent
						twoColumnCta1 {
							target
							title
							url
						}
						twoColumnCta2 {
							target
							title
							url
						}
						twoColumnImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						twoColumnImageBackgroundColor
					}
					whatWeDoTwoColumnEvents {
						twoColumnHeading
						twoColumnTagline
						twoColumnContent
						twoColumnBackground
						twoColumnCta1 {
							target
							title
							url
						}
						twoColumnCta2 {
							target
							title
							url
						}
						twoColumnImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						twoColumnBackgroundImage {
							node {
								altText
								sourceUrl
							}
						}
					}
					whatWeDoTwoColumnServices {
						twoColumnHeading
						twoColumnContent
						twoColumnTagline
						twoColumnBackground
						twoColumnImageBackgroundColor
						twoColumnCta1 {
							target
							title
							url
						}
						twoColumnCta2 {
							target
							title
							url
						}
						twoColumnImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					whatWeDoTwoColumnOffsetting {
						twoColumnTagline
						twoColumnHeading
						twoColumnContent
						twoColumnBackground
						twoColumnCta1 {
							target
							title
							url
						}
						twoColumnCta2 {
							target
							title
							url
						}
						twoColumnImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						twoColumnVideoYoutube
						twoColumnVideo {
							node {
								altText
								sourceUrl
								mediaItemUrl
							}
						}
					}
					whatWeDoClientTestimonials {
						selectClientTestimonials {
							nodes {
								... on WpReview {
									title
									date(formatString: "DD MMMM, YYYY")
									reviewTypes {
										nodes {
											taxonomyName
											slug
											termTaxonomyId
										}
									}
									reviewPostFields {
										reviewAuthor
										reviewAuthorDesignation
										reviewAuthorCompanyPicture {
											node {
												altText
												sourceUrl
												localFile {
													childImageSharp {
														gatsbyImageData(
															formats: WEBP
															quality: 80
															transformOptions: {
																cropFocus: CENTER
																fit: COVER
															}
															layout: CONSTRAINED
															placeholder: BLURRED
															width: 80
														)
													}
												}
											}
										}
										reviewQuote
										reviewStarSelect
										reviewAuthorProfilePicture {
											node {
												altText
												sourceUrl
												localFile {
													childImageSharp {
														gatsbyImageData(
															formats: WEBP
															quality: 80
															transformOptions: {
																cropFocus: CENTER
																fit: COVER
															}
															layout: CONSTRAINED
															placeholder: BLURRED
															width: 56
														)
													}
												}
											}
										}
									}
									terms {
										nodes {
											taxonomyName
											name
										}
									}
								}
							}
						}
					}
					whatWeDoGetInTouch {
						getInTouchHeading
						getInTouchContent
						getInTouchCta1 {
							target
							title
							url
						}
						getInTouchCta2 {
							target
							title
							url
						}
						getInTouchBackground {
							node {
								altText
								sourceUrl
							}
						}
					}
				}
				seoFields {
					localBusinessSchema
					metaDescription
					opengraphDescription
					opengraphTitle
					title
					image {
						node {
							altText
							sourceUrl
							mediaDetails {
								height
								width
							}
						}
					}
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const {
		pageData: { seoFields, whatWeDo },
	} = data;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "What We Do",
				item: {
					url: `${siteUrl}/what-we-do`,
					id: `${siteUrl}/what-we-do`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/what-we-do`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.sourceUrl}`,
							width: `${seoFields?.image?.node.mediaDetails.width}`,
							height: `${seoFields?.image?.node.mediaDetails.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<HeroBanner
					heading={whatWeDo?.whatWeDoBannerSection.bannerSectionHeading}
					tagline={whatWeDo?.whatWeDoBannerSection.bannerSectionTagline}
					content={whatWeDo?.whatWeDoBannerSection.bannerSectionContent}
					btnCta1={whatWeDo?.whatWeDoBannerSection.bannerSectionCta1}
					btnCta2={whatWeDo?.whatWeDoBannerSection.bannerSectionCta2}
					bgImage={
						whatWeDo?.whatWeDoBannerSection.bannerSectionImage.node?.sourceUrl
					}
					twoColumn={true}
				/>
				<TwoColumnImageContent
					heading={whatWeDo?.whatWeDoTwoColumnProgramme.twoColumnHeading}
					content={whatWeDo?.whatWeDoTwoColumnProgramme.twoColumnContent}
					tagline={whatWeDo?.whatWeDoTwoColumnProgramme.twoColumnTagline}
					btnCta1={whatWeDo?.whatWeDoTwoColumnProgramme.twoColumnCta1}
					btnCta2={whatWeDo?.whatWeDoTwoColumnProgramme.twoColumnCta2}
					image={whatWeDo?.whatWeDoTwoColumnProgramme.twoColumnImage.node}
					bgColor={
						whatWeDo?.whatWeDoTwoColumnProgramme.twoColumnImageBackgroundColor
					}
					textColor={"#000"}
				/>
				<TwoColumnImageContent
					heading={whatWeDo?.whatWeDoTwoColumnEvents.twoColumnHeading}
					content={whatWeDo?.whatWeDoTwoColumnEvents.twoColumnContent}
					tagline={whatWeDo?.whatWeDoTwoColumnEvents.twoColumnTagline}
					btnCta1={whatWeDo?.whatWeDoTwoColumnEvents.twoColumnCta1}
					btnCta2={whatWeDo?.whatWeDoTwoColumnEvents.twoColumnCta2}
					image={whatWeDo?.whatWeDoTwoColumnEvents.twoColumnImage.node}
					mainBgColor={whatWeDo?.whatWeDoTwoColumnEvents.twoColumnBackground}
					textColor={"#fff"}
					bodyTextColor={"#fff"}
					borderColor={"#fff"}
					bgImage={
						whatWeDo?.whatWeDoTwoColumnEvents.twoColumnBackgroundImage.node
							.sourceUrl
					}
					flip={true}
				/>
				<TwoColumnImageContent
					heading={whatWeDo?.whatWeDoTwoColumnServices.twoColumnHeading}
					content={whatWeDo?.whatWeDoTwoColumnServices.twoColumnContent}
					tagline={whatWeDo?.whatWeDoTwoColumnServices.twoColumnTagline}
					btnCta1={whatWeDo?.whatWeDoTwoColumnServices.twoColumnCta1}
					btnCta2={whatWeDo?.whatWeDoTwoColumnServices.twoColumnCta2}
					image={whatWeDo?.whatWeDoTwoColumnServices.twoColumnImage.node}
					bgColor={
						whatWeDo?.whatWeDoTwoColumnServices.twoColumnImageBackgroundColor
					}
					textColor={"#000"}
				/>
				<TwoColumnImageContent
					heading={whatWeDo?.whatWeDoTwoColumnOffsetting.twoColumnHeading}
					content={whatWeDo?.whatWeDoTwoColumnOffsetting.twoColumnContent}
					tagline={whatWeDo?.whatWeDoTwoColumnOffsetting.twoColumnTagline}
					btnCta1={whatWeDo?.whatWeDoTwoColumnOffsetting.twoColumnCta1}
					btnCta2={whatWeDo?.whatWeDoTwoColumnOffsetting.twoColumnCta2}
					image={whatWeDo?.whatWeDoTwoColumnOffsetting.twoColumnImage.node}
					mainBgColor={
						whatWeDo?.whatWeDoTwoColumnOffsetting.twoColumnBackground
					}
					textColor={"#fff"}
					bodyTextColor={"#fff"}
					borderColor={"#fff"}
					flip={true}
					isBgShape={true}
				/>
				<ClientTestimonials
					testimonials={
						whatWeDo?.whatWeDoClientTestimonials.selectClientTestimonials.nodes
					}
				/>
				<CtaBanner
					heading={whatWeDo?.whatWeDoGetInTouch.getInTouchHeading}
					content={whatWeDo?.whatWeDoGetInTouch.getInTouchContent}
					btnCta1={whatWeDo?.whatWeDoGetInTouch.getInTouchCta1}
					btnCta2={whatWeDo?.whatWeDoGetInTouch.getInTouchCta2}
					bgImage={whatWeDo?.whatWeDoGetInTouch.getInTouchBackground?.node}
					textPosition="start"
				/>
			</Layout>
		</>
	);
};

export default WhatWeDoPage;
